import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import { rhythm } from '../utils/typography';

const Bio = () => {
    const data = useStaticQuery(graphql`
        query BioQuery {
            avatar: file(absolutePath: { regex: "/profile-pic./" }) {
                childImageSharp {
                    fixed(width: 50, height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            site {
                siteMetadata {
                    author {
                        name
                        summary
                    }
                    social {
                        github
                    }
                }
            }
        }
    `);

    const { author, social } = data.site.siteMetadata;
    return (
        <div
            style={{
                display: 'flex',
                marginBottom: rhythm(2.5),
            }}
        >
            <Image
                fixed={data.avatar.childImageSharp.fixed}
                alt={author.name}
                style={{
                    marginRight: rhythm(1 / 2),
                    marginBottom: 0,
                    minWidth: 50,
                    borderRadius: '100%',
                }}
                imgStyle={{
                    borderRadius: '50%',
                }}
            />
            <p>
                Written by
                {' '}
                <strong>{author.name}</strong>
                {' '}
                {author.summary}
                {' '}
                Check out
                {' '}
                <Link to="/">
                    the home page
                </Link>
                {' '}
                or
                {' '}
                <a href={`https://github.com/${social.github}`}>
                    his GitHub
                </a>
                !
            </p>
        </div>
    );
};

export default Bio;
